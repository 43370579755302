<template>
  <div class="home-wrapper--provider">
    <el-container class="wrapper-provider">
      <el-main>
        <div class="wrapper-provider-info d--grid">
          <div class="providers-left">
            <h2>{{ $t(`${base}.title`) }}</h2>
            <p>{{ $t(`${base}.description`) }}</p>
          </div>
          <div class="providers-middle">
            <svg-icon
              name="home/travelers-provider"
              width="170px"
              height="170px"
            />
          </div>
          <div class="providers-right">
            <div v-if="hasProviders" class="wrapper-providers-image">
              <Swiper ref="swiperProviders" :options="swiperOption">
                <SwiperSlide v-for="(provider, i) in providers" :key="i">
                  <div class="provider-image" :class="`provider-${i}`">
                    <LadImage
                      :src="provider.avatar.url"
                      :srcset="provider.avatar.srcset"
                      :alt="provider.name"
                      :max-width="200"
                    />
                  </div>
                </SwiperSlide>

                <div slot="pagination" class="swiper-pagination"></div>
              </Swiper>
              <div class="swiper-description">
                <p class="provider-name">{{
                  $t(`${base}.name_team`, { name: providers[current].name })
                }}</p>
                <el-rate
                  v-model="providers[current].score"
                  show-score
                  :score-template="providers[current].score.toPrecision(3)"
                  disabled
                  aria-label="reviews"
                />
                <p
                  class="provider-comments"
                  v-html="
                    $tc(`${base}.comments`, providers[current].comments, {
                      comments: providers[current].comments,
                    })
                  "
                ></p>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CacheComponentMixin from '@/utils/mixin/cache-components'

export default {
  name: 'LadHomeProviders',
  components: {
    LadImage: () =>
      import(
        /* webpackChunkName: "lad-image" */ '@/components/frontends/Image/index'
      ),
  },
  mixins: [CacheComponentMixin],
  data() {
    return {
      base: 'experience.provider',
      providers: [],
      current: 0,
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        },
        loop: true,
        on: {
          slideChange: () => {
            const swiper = this.$refs.swiperProviders.$swiper

            if (!!swiper && swiper.activeIndex && !isNaN(swiper.activeIndex)) {
              this.current = swiper.activeIndex % this.providers.length
            }
          },
        },
      },
    }
  },
  computed: {
    hasProviders() {
      return this.providers.length > 0
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const { data, status } = await this.$axios.get(`/api/local/top`)

        if (status === 'success') {
          const list = []
          data.forEach((item) => {
            list.push({
              uuid: item.uuid,
              name: item.name,
              score: parseFloat(item.score),
              comments: parseFloat(item.comments),
              avatar: {
                url: item.avatar.src,
                srcset: item.avatar.srcset,
              },
            })
          })
          this.providers = list
        }
      } catch (error) {}
    },
  },
}
</script>

<style lang="scss">
.home-wrapper--provider {
  background-color: white;

  .el-main {
    padding: 30px 0;
  }

  .wrapper-provider-info {
    grid-template-columns: 1.5fr auto 1fr;
    align-items: center;
    grid-gap: 25px;
    overflow: hidden;

    .providers-left {
      max-width: 470px;

      h2 {
        line-height: 1.4;
      }
    }

    .providers-middle {
      text-align: center;
    }

    .providers-right {
      position: relative;

      img {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        border: 7px solid #fff;
        object-fit: cover;
        margin-top: 20px;
      }

      .swiper-container {
        max-width: 550px;

        .swiper-slide {
          width: 190px; /** ancho del slider activo */
          text-align: center;
          visibility: hidden;
          opacity: 0;
          transition: all 0.2s;

          &-prev,
          &-active,
          &-next {
            visibility: visible;
            opacity: 1;
          }

          .provider-image {
            padding: 25px 0;
          }

          &.swiper-slide-prev {
            text-align: right;
            margin-right: -30px;
            // width: 140px; /** ancho del slider */

            img {
              width: 120px;
              height: 120px;
            }
          }

          &.swiper-slide-active {
            text-align: center;
            z-index: 10;

            img {
              width: 160px;
              height: 160px;
              margin-top: 0;
            }
          }

          &.swiper-slide-next {
            text-align: left;
            margin-left: -30px;
            // width: 140px; /** ancho del slider */

            img {
              width: 120px;
              height: 120px;
            }
          }
        }
      }

      .swiper-description {
        text-align: center;
        padding: 0 30px 15px;

        .provider-name {
          line-height: 24px;
          font-size: 18px;
          color: #303133;
          margin-bottom: 10px;
        }
        .el-rate {
          height: 25px;
        }
        .provider-comments {
          margin-top: 0;
        }
      }
    }

    @media screen and (max-width: 980px) {
      display: block;
      margin-top: 0;

      .providers-left {
        max-width: inherit;
        text-align: center;
      }

      & > * {
        display: block;
      }
    }

    @media screen and (max-width: 700px) {
      .providers-left {
        padding: 10px 20px 20px;
      }
    }

    @media screen and (max-width: 500px) {
      .providers-middle {
        display: none;
      }
    }
  }
}
</style>
